import { render, staticRenderFns } from "./Document_template.vue?vue&type=template&id=1e939c74&lang=pug&"
import script from "./Document_template.vue?vue&type=script&lang=ts&"
export * from "./Document_template.vue?vue&type=script&lang=ts&"
import style0 from "./Document_template.vue?vue&type=style&index=0&id=1e939c74&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports